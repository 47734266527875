<template>
<teleport to='#modaleZone'>
  <StatusModal v-show="showStatusModal"
  :msg="statusMsg"
  :icon="statusIcon"
  @close-modal="showStatusModal = false"
/>
</teleport>

<teleport to='#modaleZone'>
  <ModalWelcome />
</teleport>

  <!-- MODALE INSERIMENTO -->
  <teleport to="#modaleZone">
    <div class="modale" v-if="modale">
        <div class="box-invio-segnalazione">
          <button id="closeBoxSegnal" aria-label="Chiudi box segnalazione" @click="toggleModal()"><img src="../assets/img/icone/close.svg" alt=""></button>
          <div class="form-segnalazione" v-if="formVisible">
            <h2 class="titolo">Inserisci una nuova segnalazione</h2>
              <form @submit.prevent="submitSegnalazione">
                  <div class="mb-3">
                      <label for="formCategoria" class="form-label"><strong>Categoria segnalazione*</strong></label>
                      <select id="formCategoria" class="form-select" v-model="insCategoria" ref="categoria" required>
                        <option value="" disabled selected hidden>Selezionare un campo</option>
                          <option
                            v-for="categoria in categorie"
                            :key="categoria.id"
                            :value="categoria.id"
                          >
                          {{ categoria.nome }}
                          </option>
                      </select>
                      <div v-if="tempDescCategoria" class="box-descrizione mt-2">
                        <i class="fas fa-info-circle icona"></i>
                        <div>{{ tempDescCategoria }}</div>
                      </div>
                  </div>
                  <div v-if="insCategoria" class="mb-4">
                      <label for="formArgomenti" class="form-label"><strong>Argomento segnalazione*</strong></label>
                      <select id="formArgomenti" class="form-select" v-model="insArgomento" required>
                          <option value="0" disabled selected hidden>Selezionare un campo</option>
                          <option
                            v-for="argomento in argomenti"
                            :key="argomento.id"
                            :value="argomento.id"
                          >
                          {{ argomento.nome }}
                          </option>
                      </select>
                      <div v-if="tempDescArgomento" class="box-descrizione mt-2">
                        <i class="fas fa-info-circle icona"></i>
                        <div>{{ tempDescArgomento }}</div>
                      </div>
                  </div>
                  <div v-if="usaMappa == 'si'" class="area-indirizzo">
                    <div class="insmap-wrapper">
                      <l-map
                        ref="insmap"
                        @ready="loadInsMap"
                        @locationfound="onLocationFound"
                        :zoom="13"
                        :center="center"
                        :maxZoom="19"
                        id="map2"
                        :options="{LControlLayers: false, scrollWheelZoom: false}"
                        >
                        <l-tile-layer
                          :url="tileurl" :attribution="attribution"
                        >
                        </l-tile-layer>
                        
                        <l-marker
                          :lat-lng="insMarkerLatLng"
                          :draggable="isDraggable"
                          :autoPan=true
                          @dragend="setDragMarker"
                        >
                        </l-marker>
                      </l-map>
                    </div>
                    <div class="mb-3 campi-indirizzo">
                      <div class="row mb-3">
                          <!--<div :class="{'col-md-5': comune.length > 1, 'col-md-9': comune.length == 1}">-->
                        <div class="col-md-9">
                          <label for="cercaIndirizzo" class="form-label"><strong>Indirizzo*</strong></label>
                          <input type="text" class="form-control" id="cercaIndirizzo" ref="formIndirizzo" v-model="insIndirizzo" autocomplete="off" required>
                          <template v-if="!minCaratteriIndirizzo"><small>Digitare almeno 3 caratteri</small></template>
                          <template v-if="statoRicercaIndirizzo == true">
                            <small role="alert">
                              <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                              Ricerca indirizzo in corso...
                            </small>
                          </template>
                          <div v-if="indirizzoFound == false">
                            Indirizzo non trovato
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label for="insCivico" class="form-label"><strong>Numero civico</strong></label>
                          <input type="text" class="form-control" id="insCivico" v-model="form.civico" autocomplete="off">
                        </div>
                        <div v-if="this.comune.length > 1" class="col-md-4">
                          <label for="formComune" class="form-label"><strong>Comune</strong></label>
                          <select id="formComune" class="form-select" v-model="comuneSelect" required>
                              <option
                                v-for="(comuneSingolo, index) in comune"
                                :key="index"
                                :value="index"
                              >
                              {{ comuneSingolo.nome }}
                              </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <button class="btn btn-secondary" :disabled="statoRicercaIndirizzo == true || minCaratteriIndirizzo == false" @click.prevent="cercaIndirizzo">Cerca indirizzo</button>
                        </div>
                      </div>
                      <!-- <button @click.prevent="locationInsMap" class="btn btn-secondary">Centra sulla tua posizione</button> -->
                    </div>
                  </div>
                  <div class="due-righe my-3">
                    <div>
                      <label for="formNome" class="form-label"><strong>Nome*</strong></label>
                      <input type="text" class="form-control" id="formNome" v-model="form.nome" required>
                    </div>
                    <div>
                      <label for="formCognome" class="form-label"><strong>Cognome*</strong></label>
                      <input type="text" class="form-control" id="formCognome" v-model="form.cognome" required>
                    </div>
                    <div>
                      <label for="formEmail" class="form-label"><strong>E-mail*</strong></label>
                      <input type="email" class="form-control" id="formEmail" v-model="form.email" required>
                    </div>
                    <div>
                      <label for="formTelefono" class="form-label"><strong>Telefono</strong></label>
                      <input type="text" class="form-control" id="formTelefono" v-model="form.telefono">
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="formDescrizione" class="form-label"><strong>Testo della segnalazione*</strong></label>
                    <textarea class="form-control" id="formDescrizione" rows="3" v-model="form.testo" required></textarea>
                  </div>
                  <div class="mb-3">
                    <label for="formAllegati" class="form-label"><strong>Allegati</strong></label>
                    <input class="form-control" type="file" id="formAllegati" @change="uploadFiles" ref="formAllegati" name="formAllegati" multiple>
                    <small>Sono consentite solamente immagini e PDF con dimensione massima di 5MB</small>
                  </div>
                  <div v-if="insArgomento" class="mb-3">
                    <strong>Privacy Policy</strong>
                    <div class="privacy-form" v-html="privacyCustom"></div>
                    <div class="form-group form-check">
                      <input type="checkbox" class="form-check-input" id="checkPrivacy" v-model="formPrivacy" required>
                      <label class="form-check-label" for="checkPrivacy">Acconsento</label>
                    </div>
                  </div>
                  <input type="hidden" v-model="insMarkerLatLng">
                  <button class="btn btn-primary">Invia segnalazione</button>
              </form>
            </div>
        </div>
    </div>
  </teleport>
  <!-- fine MODALE INSERIMENTO -->
  

  <div class="map-wrapper">
    <l-map
      ref="mymap"
      @ready="loadMap()"
      v-model="zoom"
      v-model:zoom="zoom"
      :center="center"
      :maxZoom="19"
      id="map"
      :options="{zoomControl: false, LControlLayers: false}"
    >
      <l-tile-layer
        :url="tileurl" :attribution="attribution"
      >
      </l-tile-layer>

      <l-marker
        v-for="segnalazione in segnalazioni"
        :key="segnalazione.id"
        :lat-lng="[segnalazione.lat, segnalazione.lng]"
        @click="passaSegnalazione(segnalazione), togglePin(segnalazione.id)"
      >
        <l-icon
          :icon-anchor="iconAnchor"
          :icon-size="iconSize"
        >
        <div class="pin-marker" :class="{active: segnalazione.id === idSegnalazioneAttiva}">
          <img class="pin" :src="require(`../assets/img/icone/pin-${segnalazione.stato}.svg`)" alt="">
          <i :class="segnalazione.icona" class="icona"></i>
        </div>
        </l-icon>
      </l-marker>
    </l-map>

    <div id="searchBox" :class="{ active: lSidebarActive }">
      <div class="desktop">
        <form @submit.prevent="submitSearch()">
          <label class="visually-hidden" for="ricerca">Ricerca segnalazioni</label>
          <div class="input-bar">
            <input type="text" id="ricerca" required placeholder="Ricerca segnalazioni" v-model="termineRicerca" autocomplete="off">
            <button><i class="fas fa-search"></i></button>
          </div>
        </form>
        <button class="btnSegnalazione" @click="toggleModal()">
          <i class="fas fa-plus icona"></i> Inserisci segnalazione
        </button>
      </div>
      <div class="mobile">
        <router-link to="/segnalazioni" class="btnSearchMobile"><i class="fas fa-search"></i></router-link>
        <button class="btnSegnalazioneMobile" @click="toggleModal()">
          <i class="fas fa-plus icona"></i>
        </button>
      </div>
    </div>

    <div class="map-buttons" id="mapButtons" :class="{ active: rSidebarActive }">
      <button @click="mapZoomIn()" title="Zoom avanti"><img src="../assets/img/icone/plus.svg" alt=""></button>
      <button @click="mapZoomOut()" title="Zoom indietro" class="mb-4"><img src="../assets/img/icone/minus.svg" alt=""></button>
      <button @click="toggleLegenda()" title="Legenda"><img src="../assets/img/icone/legenda.svg" alt=""></button>
      <button @click="mapCenter()" title="Centra mappa"><img src="../assets/img/icone/centra.svg" alt=""></button>
      <!--<button @click="toggleSegnalSidebar()" title="Filtra segnalazioni"><img src="../assets/img/icone/segnalazioni.svg" alt=""></button>-->
    </div>

    <div id="leftSidebar" :class="{ active: lSidebarActive }">
      <button id="closeLButton" @click="toggleLeftSidebar()" aria-label="Chiudi sidebar"><img src="../assets/img/icone/close.svg" alt=""></button>
      <DetailSidebar
        :idSegnalazione ="idSegnalazione"
        :allegati="allegatiSegnalazione"
        :stato="statoSegnalazione"
        :categoria="catSegnalazione"
        :messaggio="messaggioSegnalazione"
        :indirizzo="indirizzoSegnalazione"
       />
    </div>

    <div id="rightSidebar" :class="{ active: rSidebarActive }">
      <button id="closeRButton" @click="toggleRightSidebar()" aria-label="Chiudi sidebar"><img src="../assets/img/icone/close.svg" alt=""></button>
      <div v-if="legenda"><Legenda /></div>
      <div v-if="segnalSidebar"><SegnalSidebar /></div>
    </div>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon
} from "@vue-leaflet/vue-leaflet";

import "leaflet/dist/leaflet.css";
import Legenda from "../components/Legenda.vue";
import SegnalSidebar from "../components/SegnalSidebar.vue";
import DetailSidebar from "../components/DetailSidebar.vue";
import ModalWelcome from "../components/ModalWelcome.vue";
import StatusModal from "../components/StatusModal.vue";
import axios from 'axios';
import { mapState } from 'vuex';
import configApi from "../../public/config.api.json";
// import { ref } from 'vue';

export default {
  name: 'Home',
  components: { Legenda, SegnalSidebar, DetailSidebar, ModalWelcome, LMap, LTileLayer, LMarker, LIcon, StatusModal },
  // setup() {
  //   const welcomeModalActive = ref(true);
  //   const toggleWelcomeModal = () => {
  //     welcomeModalActive.value = !welcomeModalActive.value;
  //   }
  //   return { welcomeModalActive, toggleWelcomeModal };
  // },
  data() {
      return {
        // center: [42.0401, 13.4363],
        // markerLatLng: [42.0401, 13.4363],
        // insMarkerLatLng: [42.0401, 13.4363],
        insMarkerLatLng: null,
        zoom:14,
        tileurl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        iconAnchor: [17, 43],
        iconSize: [34, 45],
        pinIsActive: false,
        rSidebarActive: false,
        lSidebarActive: false,
        legenda: false,
        segnalSidebar: false,
        termineRicerca: "",
        formVisible: true,
        successSubmit: false,
        idSegnalazione: null,
        allegatiSegnalazione: null,
        catSegnalazione: null,
        messaggioSegnalazione: null,
        statoSegnalazione: null,
        indirizzoSegnalazione: null,
        modale:false,
        configApi: configApi,
        categoriaFilter: null,
        argomentoFilter: null,
        privacyCustom: null,
        argomenti: [],
        tempCategoria: '',
        tempDescCategoria:'',
        tempDescArgomento:'',
        idSegnalazioneAttiva:null,
        usaMappa: "no",
        comuneSelect: 0,
        indirizzoFound: true,
        statoRicercaIndirizzo:false,
        isDraggable:true,

        // Variabili form
        insCategoria: '',
        insArgomento: '',
        insIndirizzo: '',
        minCaratteriIndirizzo:false,
        formNome: '',
        formCognome: '',
        formEmail: '',
        formTelefono: '',
        formTesto: '',
        formPrivacy: false,
        allegati:[],
        form: {
          "nome": '',
          "cognome": '',
          "email": '',
          "telefono": '',
          "testo": '',
          "civico": '',
        },
        filesAllowed:[
          'image/jpeg',
          'image/png',
          'image/gif',
          'image/bmp',
          'application/pdf'
        ],

        // Variabili per StatusModal
        showStatusModal: false,
        statusMsg: [],
        statusIcon: null
      }
  },


  watch: {
    insCategoria() {
      this.categoriaFilter = this.categorie.find(categoria => categoria.id === this.insCategoria)
      this.argomenti = this.categoriaFilter.argomenti
      this.tempDescCategoria = this.categoriaFilter.descrizione
      this.argomentoFilter = this.argomenti[0]
      this.tempDescArgomento = this.argomentoFilter.descrizione
      this.privacyCustom= this.argomentoFilter.testo_privacy
      this.usaMappa = this.argomentoFilter.uso_mappa
      this.insArgomento = this.argomenti[0].id
    },
    insArgomento() {
      this.argomentoFilter = this.argomenti.find(argomento => argomento.id === this.insArgomento)
      this.tempDescArgomento = this.argomentoFilter.descrizione
      this.privacyCustom= this.argomentoFilter.testo_privacy
      this.usaMappa = this.argomentoFilter.uso_mappa
    },
    insIndirizzo(e) {
      if (e.length >= 3) {
        this.minCaratteriIndirizzo = true
      }
      else if (e.length < 3) {
        this.minCaratteriIndirizzo = false
      }
    }
  },
  
  computed: {
    ...mapState([
        'ente',
        'segnalazioni',
        'categorie'
    ]),
    center() {
      return this.$store.state.ente.latLng
    },
    markerLatLng() {
      return this.$store.state.ente.latLng
    },
    // insMarkerLatLng() {
    //   return this.$store.state.ente.latLng
    // },
    comune() {
      return this.$store.state.ente.comune
    }
  },
  methods: {
    togglePin(elementoid) {
      this.idSegnalazioneAttiva = elementoid;
    },
    mapZoomIn() {
      this.mymap.setZoom(this.mymap.getZoom() + 1)
    },

    mapZoomOut() {
      this.mymap.setZoom(this.mymap.getZoom() - 1)
    },

    mapCenter() {
      this.mymap.setView(this.center, 14);
    },

    toggleRightSidebar() { 
      this.rSidebarActive = !this.rSidebarActive;
    },

    toggleLeftSidebar() { 
      this.lSidebarActive = !this.lSidebarActive;
    },
    toggleLegenda() {
      this.legenda = true;
      this.segnalSidebar = false;
      this.rSidebarActive = true;
    },
    toggleSegnalSidebar() {
      this.segnalSidebar = true;
      this.legenda = false;
      this.rSidebarActive = true;
    },
    passaSegnalazione(segnalazione) {
      this.lSidebarActive = true;
      this.idSegnalazione = segnalazione.id;
      this.catSegnalazione = segnalazione.categoria[0].nome;
      this.messaggioSegnalazione = segnalazione.messaggio;
      if (segnalazione.allegati) {
        this.allegatiSegnalazione = segnalazione.allegati[0];
      }
      this.statoSegnalazione = segnalazione.stato;
      this.indirizzoSegnalazione = segnalazione.indirizzo;
      this.mymap.panTo([segnalazione.lat, segnalazione.lng]);
    },
    loadMap() {
      this.mymap = this.$refs.mymap.leafletObject;
    },
    async loadInsMap() {
      this.insmap = this.$refs.insmap.leafletObject
      this.insMarkerLatLng = this.center
      // let response = await fetch('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + lat + '&lon='+ lng +'')
      // let posizione = await response.json()
      // this.insIndirizzo = posizione.display_name
    },
    locationInsMap() {
      this.insmap.locate()
    },
    async onLocationFound(e) {
      this.markerLatLng = e.latlng
      await fetch('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + e.latlng['lat'] + '&lon='+ e.latlng['lng'] +'')
      .then(res => res.json())
      .then((data) => {
        this.insIndirizzo = data.display_name
        this.insmap.panTo(e.latlng);
      })
      .catch(err => console.log(err.message))      
    },
    async setClickMarker(e) {
      const latLngTrue = (Object.prototype.hasOwnProperty.call(e, "latlng"));
      if (latLngTrue) {
        this.insMarkerLatLng = e.latlng
        let lat = String(e.latlng.lat);
        let lng = String(e.latlng.lng);
        const response = await fetch('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + lat + '&lon='+ lng +'')
        const posizione = await response.json()
        if (posizione.display_name.includes(this.comune[this.comuneSelect].nome)) {
          this.insIndirizzo = posizione.display_name
          this.insmap.panTo(e.latlng)
          this.indirizzoFound = true
        }
        else {
          this.insIndirizzo = ""
          this.insmap.panTo(this.center)
          this.insMarkerLatLng = this.center
          this.indirizzoFound = false
        }
      }
    },
    async setDragMarker(e) {
      this.statoRicercaIndirizzo = true
      this.isDraggable = false
      let lat = String(e.target._latlng.lat);
      let lng = String(e.target._latlng.lng);
      await fetch('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + lat + '&lon='+ lng +'')
      .then(res => res.json())
      .then((data) =>{
        if (data.display_name.includes(this.comune[this.comuneSelect].nome)) {
        this.insIndirizzo = data.display_name
        this.insmap.panTo(e.target._latlng);
        this.indirizzoFound = true
        }
        else {
          this.insmap.panTo(this.center)
          this.insMarkerLatLng = this.center
          this.insIndirizzo = ""
          this.indirizzoFound = false
        }
      })
      .then(() => {
        this.statoRicercaIndirizzo = false
        this.isDraggable = true
      })
      .catch(err => console.log(err.message))
    },
    async cercaIndirizzo() {
      this.statoRicercaIndirizzo = true
        let strIndirizzo = (this.insIndirizzo + " " + this.comune[this.comuneSelect].nome_dettagliato).split(' ').join('+')
        let url = await 'https://nominatim.openstreetmap.org/?addressdetails=1&q='+ strIndirizzo +'&format=json&limit=1'
          await fetch(url)
          .then(res => res.json())
          .then((data) => {
              if (data.length > 0) {
                this.insIndirizzo = data[0].display_name
                this.insmap.panTo([data[0].lat, data[0].lon]);
                this.indirizzoFound = true
                this.insMarkerLatLng = [data[0].lat, data[0].lon]
              }
              else {
                this.insIndirizzo = null
                this.indirizzoFound = false
              }
          })
          .then(() => {
            this.statoRicercaIndirizzo = false
          })
          .catch(err => console.log(err.message))
    },
    toggleModal() {
      this.modale = !this.modale
      this.formVisible = true
    },
    uploadFiles (event) {
        let formatAllowed = true
        let sizeAllowed = true
        let numAllegati = 0
        this.statusMsg = []
        this.allegati = event.target.files
        //Converto l'oggetto files in un array
        let tempAllegati = Object.values(this.allegati)
        // Controlli su formato, numero file e dimensione
        tempAllegati.forEach(e => {
          numAllegati++
          if (!this.filesAllowed.includes(e.type)) {
            formatAllowed = false
          }
          if (e.size > 5242880) {
            sizeAllowed = false
          }
        })
        if (formatAllowed === false) {
          this.showStatusModal = true
          this.statusIcon = "error"
          this.statusMsg.push("Sono permessi esclusivamente i seguenti formati: pdf, jpg, jpeg, png, gif, bmp.")
          this.$refs.formAllegati.value = null;
        }
        if (sizeAllowed === false) {
          this.showStatusModal = true
          this.statusIcon = "error"
          this.statusMsg.push("La dimensione massima di un allegato non può superare i 5MB.")
          this.$refs.formAllegati.value = null;
        }
        if (numAllegati > 10) {
          this.showStatusModal = true
          this.statusIcon = "error"
          this.statusMsg.push("Sono ammessi al massimo 10 allegati.")
          this.$refs.formAllegati.value = null;
        }
    },
    async submitSegnalazione() {
      this.statusMsg = []
      let formData = new FormData();
      for ( var key in this.form ) {
        formData.append(key, this.form[key]);
      }
      formData.append("categoria", this.insCategoria)
      formData.append("argomento", this.insArgomento)
      formData.append("indirizzo", this.insIndirizzo)
      formData.append("latlng", this.insMarkerLatLng)
      // Gestione upload allegati
      let fileArray = []
      let filePathsPromises = []
      for (let i = 0; i < this.allegati.length; i++) {
        let file = this.allegati.item(i)
        filePathsPromises.push(this.toBase64(file));
        fileArray.push({
          'name': this.allegati.item(i).name,
          // 'type': this.allegati.item(i).type,
          // 'size': this.allegati.item(i).size,
          // 'content': '',
        })
        // this.getBase64(file)
        // .then(res => fileArray.content = res)  
        // .then(formData.append('files[]', JSON.stringify(fileArray)))  
      }
      const filePaths = await Promise.all(filePathsPromises)
      for (let i = 0; i < fileArray.length; i++) {
        fileArray[i].content = filePaths[i]
        formData.append(`allegatoName[${i}]`, fileArray[i].name)
        formData.append(`allegatoContent[${i}]`, fileArray[i].content)
      }
      // Verifica se lat e lng non sono vuoti
      if (this.insMarkerLatLng != "" || this.insMarkerLatLng != null) {
        // Esegue il post della segnalazione
        axios.post(configApi.postUrl, 
          formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if (res.data.esito == "ok"){
            this.modale = false
            this.showStatusModal = true
            this.statusMsg.push(res.data.msg)
            this.statusIcon = 'success'
          }
          else if (res.data.esito == "ko"){
            this.showStatusModal = true
            this.statusMsg.push(res.data.msg)
            this.statusIcon = 'error'
          }
          else {
            this.showStatusModal = true
            this.statusMsg.push("Errore generico, riprova tra poco")
            this.statusIcon = 'error'
          }
          // Semplice console log, rimuovere
          for (var pair of formData.entries()) {
            console.log(pair[0]+ ' - ' + pair[1]); 
          }
          //Pulisce i campi della segnalazione
          this.clearFormSegnalazione()
        })
        .catch((error) => {
          this.showStatusModal = true
          this.statusIcon = "error"
          this.statusMsg.push("Errore di comunicazione con il server.<br>Si prega di riprovare tra qualche minuto")
          this.clearFormSegnalazione()
        });
      }
      else {
        this.showStatusModal = true
        this.statusIcon = "error"
        this.statusMsg.push("Errore nella lettura della latitudine e longitudine.<br>Si prega di inserire di nuovo l'indirizzo")
        this.$refs.formIndirizzo.value = null;
      }
    },
    clearFormSegnalazione() {
      this.form = {
        "nome": '',
        "cognome": '',
        "email": '',
        "telefono": '',
        "testo": '',
        "civico": '',
      }
      this.formPrivacy = false
      this.insMarkerLatLng = null
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    test() {
      console.log("TEST!")
    },
    submitSearch() {
      this.$router.push({ path: '/segnalazioni', query: { search: this.termineRicerca } })
    }
  },
}
</script>